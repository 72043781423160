 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #D71920;
  --bs-btn-border-color:#b70910;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b70910;
  --bs-btn-hover-border-color: #b70910;
  --bs-btn-focus-shadow-rgb: #b70910;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b70910;
  --bs-btn-active-border-color: #b70910;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}
@media (min-width: 1200px) {
  .fs-1 {
      font-size: 4rem !important;
  }
}
strong{
  color: #D71920;
}
a{
  color: #D71920;
}